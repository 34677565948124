import React, { Component } from 'react';

import logo from './img/logo-beige.png';
import Portrait from './img/portrait-049.jpg';
import AllThePeople from './img/allthepeoplecover.jpg';
import SixDegrees from './img/6degreescover.jpg';
import TheFoodKeeper from './img/thefoodkeeper.jpg';
import StylingStilLife from './img/styling-still-life.jpg';
import ScrollDown from './img/down-arrow.png';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

import 'animate.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'popper.js';
import $ from 'jquery';
import 'bootstrap';
import _ from 'lodash';

import {getContents} from './content'
import RichText from '@madebyconnor/rich-text-to-jsx';

class App extends Component {
  state = {
    texts: [],
    portfolio: null
  }
  componentWillMount() {
    getContents("textContent").then((r) => (
      this.setState({texts: r.items})
    ))
    getContents("portfolio").then((r) => (
      this.setState({portfolio: r.items[0]})
    ))
  }

  componentDidMount() {
    AOS.init();
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
    });

    // https://codepen.io/bfintal/pen/Ejvgrp
    const ElInViewPort = id => {
      const el = document.querySelector(id)
    	const scroll = window.scrollY || window.pageYOffset
    	const boundsTop = el.getBoundingClientRect().top + scroll

    	const viewport = {
    	   top: scroll,
    	   bottom: scroll + window.innerHeight,
    	}

      const bounds = {
  		    top: boundsTop,
  		    bottom: boundsTop + el.clientHeight,
    	}

    	return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
    		|| ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
    }
    const raf =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function( callback ) {
          window.setTimeout( callback, 1000 / 60 )
      }
    document.addEventListener('DOMContentLoaded', () => {
      const elements = ['#section-intro', '#section-writing', '#section-styling', '#section-consulting']

    	const handler = () => raf( () => {

        const elinview = {
          intro: ElInViewPort(elements[0]),
          writing: ElInViewPort(elements[1]),
          styling: ElInViewPort(elements[2]),
          consulting: ElInViewPort(elements[3])
        }

        var keys = _.keys(_.pickBy(elinview));

        if (keys[keys.length - 1] === 'intro') {
          document.body.style.backgroundColor = '#9C5C4E';
          document.body.style.color = "#DDD7CF";
          const textTitles = document.querySelectorAll('.text-titles');
          [...textTitles].forEach((title) => (
            title.style.textShadow = '2px 2px 0px #9C5C4E'
          ))
        } else if (keys[keys.length - 1] === 'writing') {
          document.body.style.backgroundColor = '#677A89';
          document.body.style.color = "#DDD7CF";
          const textTitles = document.querySelectorAll('.text-titles');
          [...textTitles].forEach((title) => (
            title.style.textShadow = '2px 2px 0px #677A89'
          ))
        } else if (keys[keys.length - 1] === 'styling') {
          document.body.style.backgroundColor = '#9C5C4E';
          const textTitles = document.querySelectorAll('.text-titles');
          [...textTitles].forEach((title) => (
            title.style.textShadow = '2px 2px 0px #9C5C4E'
          ))
        } else if (keys[keys.length - 1] === 'consulting') {
          document.body.style.backgroundColor = '#677A89';
          const textTitles = document.querySelectorAll('.text-titles');
          [...textTitles].forEach((title) => (
            title.style.textShadow = '2px 2px 0px #677A89'
          ))
        }
    	} )

    	handler()
    	window.addEventListener('scroll', handler)
    } )
  }
  render() {
    const selectText = (contentId) => {
      const text = _.filter(this.state.texts, (t) => (t.sys.id === contentId))
      if (text.length === 0) {
        return
      }
      return text[0].fields.textContent
    }
    return (
      <div className="container-fluid">
        <div id="section-1" className="section section-home row  animated fadeIn">
          <div className="col-4 col-md-2 text-left pl-3 pt-3 pr-md-5 logo-top"><img src={logo} class="img-fluid" /></div>
          <div className="col-9"></div>
          <div className="col-3 text-right text-subtitles pr-3 pt-2 contact-top"><a href="mailto:contact@emilybesa.com">contact</a></div>
          <div className="col-12 col-md-10 offset-md-1 introduction text-titles mb-5">
            Emily Besa is a creative consultant who collaborates with artists, designers, and individuals to bring out their authentic voice and vision on various platforms and through diverse mediums. She is a <a href="#section-writing">writer</a> and a <a href="#section-styling">stylist</a>.
          </div>
          <div className="col-12 text-center text-subtitles scroll-down">
            scroll down<br />
            <a href="#section-intro"><img src={ScrollDown} height="25" className="pt-1" /></a></div>
        </div>
        <div id="section-intro" className="row mb-5 mt-5" data-aos="fade-in" data-aos-duration="1000">
          <div className="col-12 col-md-8 offset-md-2 intro-block">
            <div className="row no-gutters mb-5 mt-5" data-aos="fade-in" data-aos-duration="1000">

            <div className="col-12 text-subtitles">HELLO THERE!</div>
              <div className="col-12 col-md-5 mb-3 mb-md-0">
                <img className="img-fluid" src={Portrait} />
              </div>
              <div className="col-12 col-md-6  mt-md-5 offset-md-1 text-intro-block">
                <div className="text-p pr-md-5 text-introduction">
                  <RichText richText={selectText("5EXCdMKygI64N1ApdgTRcO")} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="section-writing" className="section section-writing row no-gutters mb-5 pb-5" data-aos="fade-up">
          <div className="col-12 text-center text-titles underline pt-5 mb-2">Writing</div>
          <div className="col-10 offset-1 col-md-6 offset-md-3 text-center text-p mb-2" data-aos="fade-up"><RichText richText={selectText("6vEjCAaB3HXKAll0x38BzG")} /></div>
          <div className="col-12 col-md-6 offset-md-2 all-the-people mt-5" data-aos="fade-right">
            <div className="row">

              <div className="col-12 text-subtitles">WRITING</div>
              <div className="col-5">
                <img className="img-fluid" src={AllThePeople} />
              </div>
              <div className="col-7">
                <div className="text-titles mt-2 mt-md-4 subtitle-left">All the People</div>
                <span className="text-p"><RichText richText={selectText("7vAR0XlyeejbZSbLI5oYPR")} /></span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 offset-md-4 six-degrees" data-aos="fade-left">
            <div className="row mb-5 mb-md-0 justify-content-end">
              <div className="col-12 text-right text-subtitles">WRITING</div>
              <div className="col-7 text-right">
                <div className="text-titles mt-2 mt-md-4 subtitle-right">6 Degrees Berlin</div>
                <span className="text-p"><RichText className="text-p pl-5" richText={selectText("5Ds0B1Wg2xpOXkDNjKouEJ")} /></span>
              </div>
              <div className="col-5">
                <img className="img-fluid" src={SixDegrees} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 offset-md-2 the-food-keeper" data-aos="fade-in">
            <div className="row mb-5 mb-md-0">
              <div className="col-12 text-subtitles">WRITING</div>
              <div className="col-5">
                <img className="img-fluid" src={TheFoodKeeper} />
              </div>
              <div className="col-7">
                <div className="text-titles mt-2 mt-md-4 subtitle-left">The Food Keeper</div>
                <span className="text-p"><RichText richText={selectText("6jaVgpw0uqYoQlkGLAO3JU")} /></span>
              </div>
            </div>
          </div>
        </div>
        <div id="section-styling" className="section section-styling row pt-5 pb-5 mb-5" data-aos="fade-up">
          <div className="col-12 col-md-8 offset-md-2 section-styling-inside">

            <div className="text-center text-titles underline mb-2">Styling</div>
              <div className="row no-gutters">
                <div className="col-12 col-md-10 offset-md-1">
                <div className="text-left text-p pb-3 styling-intro"><RichText richText={selectText("5wIMKXCnQXc34IyoWRrU1Z")} /></div>
              </div>
            </div>
            <div className="text-subtitles subtitle-down mt-5">STYLING</div>
            <div className="row no-gutters mb-5">
              <div className="col-12 col-md-6">
                <div className="text-titles">Personal Stylist</div>
                <div className="text-p pr-5">
                  <RichText richText={selectText("43HrUbZW6JHFI7B6sRrZ7e")} />
                </div>
              </div>
              <div className="col-12 col-md-5 offset-md-1 mt-2 mt-md-5">
                <img className="img-fluid" src={StylingStilLife} />
              </div>
            </div>
          </div>
          <div className="col-10 offset-1 text-right" data-aos="fade-in">
            <div className="text-subtitles mt-5 subtitle-down">STYLING</div>
            <p className="text-titles">Portfolio</p>
          </div>
          <div className="col-12 p-0" data-aos="fade-in">
            {this.state.portfolio ?
              <div className="portfolio-images">
              {this.state.portfolio.fields.images.map((image) => (
                <img className="pr-3" src={image.fields.file.url} key={image.sys.id} alt={image.fields.file.fileName} />
              ))}
              </div>
            : null }
            </div>
          <div className="col-12 col-md-10 offset-md-1 text-right mt-1 mt-md-3">
            <p className="text-subtitles">swipe right</p>
          </div>
        </div>
        <div id="section-consulting" className="section section-consulting container-fluid pt-5 mb-5" data-aos="fade-up">
          <div className="col-12 text-center underline mb-2"><h2 className="text-titles">Creative consulting</h2></div>
          <div className="col-12 col-md-6 offset-md-3 text-center text-p pb-5 pb-md-0 mb-5">

            <RichText richText={selectText("4srSpiAao1piTcu5Jb3vi5")} />
          </div>
        </div>
        <div id="section-contact" data-aos="fade-in">
          <div className="text-titles text-center mt-5"><a href="mailto:contact@emilybesa.com">get in touch</a></div>
          <div className="contact-text text-subtitles text-center mb-5"><a href="mailto:contact@emilybesa.com">&nbsp; &nbsp; &nbsp; at contact@emilybesa.com</a></div>
        </div>
        <footer id="footer" className="col-12 text-subtitles mb-0 mb-md-3">
          <div className="row">
            <div className="footer-left col-6 text-left">
              <p><a href="https://www.instagram.com/emilybasabesa/" target="_blank">instagram</a> - <a href="https://www.linkedin.com/in/emilybasabesa" target="_blank">linkedin</a></p>
              <p><a href="mailto:contact@emilybesa.com">contact@emilybesa.com</a></p>
            </div>
            <div className="footer-right col-6 text-right">
              website by <a href="http://www.ocrestudio.co">ocré studio</a>
              <p>© Emily Basa Besa, 2021</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
