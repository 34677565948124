import {createClient} from 'contentful';
import {accessToken, spaceId} from './settings';

const contentfulClient = createClient({
  space: spaceId,
  accessToken: accessToken,
  include: 2,
})

export const getContents = (contentType) => {
  return contentfulClient.getEntries({content_type: contentType})
}
